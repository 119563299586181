import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { H3, P, Subtitle5 } from './Typography';
import Tick from '../assets/icons/tick.svg';

const Wrapper = styled.div`
  border-radius: 20px;
  background: linear-gradient(80deg, #13273f 13.57%, #4d2161 104.94%);
  display: flex;
  flex-direction: column;
  padding: 45px 100px 60px;
`;

const Title = styled(H3)`
  font-size: 22px;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
`;

const ColumnTitle = styled(Subtitle5)`
  color: #f4f7f9;
  line-height: 24px;
`;

const ItemTitle = styled(ColumnTitle)`
  margin-bottom: 0;
`;

const Subtitle = styled(P)`
  color: #9197a9;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
`;

const GridContainer = styled.div`
  margin: 40px 0 45px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const VerticalLine = styled.div`
  height: 100%;
  background: rgba(219, 227, 235, 0.19);
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 13px;
`;

const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ItemDescription = styled(P)`
  color: #9197a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

const HorizontalLine = styled.div`
  display: flex;
  height: 1px;
  background: rgba(219, 227, 235, 0.19);
  margin: 0 -100px 58px;
`;

const BottomText = styled(H3)`
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled(Link)`
  padding: 15px 30px;
  border-radius: 5px;
  background: #353c6d;
  border: none;
  margin: 0 auto;
  text-decoration: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  width: calc(180px - 30px * 2);
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`;

const TickWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: #2cd19e;
`;

const TickIcon = styled(Tick)`
  color: #13273f;
  transform: scale(0.7);
`;

interface BigPurpleCardProps {
  title?: string;
  subTitle?: string;
  leftColumnTitle?: string;
  rightColumnTitle?: string;
  leftColumn: { title?: string; description: string }[];
  rightColumn: { title?: string; description: string }[];
  bottomText: string;
  button: { label: string; url: string };
  descriptionStyles?: React.CSSProperties;
}

const BigPurpleCard: React.FunctionComponent<BigPurpleCardProps> = ({
  title,
  subTitle,
  leftColumnTitle,
  rightColumnTitle,
  leftColumn,
  rightColumn,
  bottomText,
  button,
  descriptionStyles,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <Subtitle>{subTitle}</Subtitle>
    <GridContainer>
      <Column>
        {leftColumnTitle && <ColumnTitle>{leftColumnTitle}</ColumnTitle>}
        {leftColumn.map((item) => (
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              {item.title && <ItemTitle>{item.title}</ItemTitle>}
              <ItemDescription style={descriptionStyles}>
                {item.description}
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
        ))}
      </Column>
      <VerticalLine />
      <Column>
        {rightColumnTitle && <ColumnTitle>{rightColumnTitle}</ColumnTitle>}
        {rightColumn.map((item) => (
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              {item.title && <ItemTitle>{item.title}</ItemTitle>}
              <ItemDescription style={descriptionStyles}>
                {item.description}
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
        ))}
      </Column>
    </GridContainer>
    <HorizontalLine />
    <BottomText>{bottomText}</BottomText>
    <Button to={button.url} target="_blank">
      {button.label}
    </Button>
  </Wrapper>
);

export default BigPurpleCard;
